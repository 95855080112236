@font-face {
  font-family: "Gordita";
  font-weight: 800;
  src: local("Gordita"),
    url(./Fonts/Gordita/gordita-black.otf) format("opentype");
}

@font-face {
  font-family: "Gordita";
  font-weight: 800;
  font-style: italic;
  src: local("Gordita"),
    url(./Fonts/Gordita/gordita-blackitalic.otf) format("opentype");
}
@font-face {
  font-family: "Gordita";
  font-weight: 600;
  src: local("Gordita"),
    url(./Fonts/Gordita/gordita-bold.otf) format("opentype");
}
@font-face {
  font-family: "Gordita";
  font-weight: 600;
  font-style: italic;
  src: local("Gordita"),
    url(./Fonts/Gordita/gordita-bolditalic.otf) format("opentype");
}
@font-face {
  font-family: "Gordita";
  font-weight: lighter;
  src: local("Gordita"),
    url(./Fonts/Gordita/gordita-light.otf) format("opentype");
}
@font-face {
  font-family: "Gordita";
  font-weight: normal;
  src: local("Gordita"),
    url(./Fonts/Gordita/gordita-regular.otf) format("opentype");
}
@font-face {
  font-family: "Gordita";
  font-weight: 500;
  src: local("Gordita"),
    url(./Fonts/Gordita/gordita-medium.otf) format("opentype");
}

@font-face {
  font-family: "Gordita";
  font-weight: 200;
  src: local("Gordita"),
    url(./Fonts/Gordita/gordita-thin.otf) format("opentype");
}
@font-face {
  font-family: "Gordita";
  font-weight: 900;
  src: local("Gordita"),
    url(./Fonts/Gordita/gordita-ultra.otf) format("opentype");
}
